<template>
    <div class="page_content">
        <div class="page_detail">

            <a-form :label-col="labelColEdit" :wrapper-col="wrapperColEdit">
                <a-form-item required label="指标分类">
                    <a-tree-select  :replaceFields="{children:'children', label:'title', value: 'id' }" v-model:value="fromData.cate_id" style="width: 100%" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :placeholder="nullDatatext" tree-default-expand-all :tree-data="classifyListData">
                    </a-tree-select>
                </a-form-item>
                <a-form-item required name='name' label="检测项目" :rules="[{ required: true, message: '检测项目不能为空',tagger:'blur' }]">
                    <a-input v-model:value="fromData.name" placeholder="请输入检测项目" />
                </a-form-item>
                <a-form-item name='name' label="抽样方案" :rules="[{ required: true, message: '抽样方案不能为空',tagger:'blur' }]">
                    <a-input v-model:value="fromData.programme" placeholder="请输入抽样方案" />
                </a-form-item>
                <a-form-item name='name' label="检测水平" :rules="[{ required: true, message: '检测水平不能为空',tagger:'blur' }]">
                    <a-input v-model:value="fromData.detection_level" placeholder="请输入检测水平" />
                </a-form-item>
                <a-form-item required name='name' label="接收质量限(AQL)" :rules="[{ required: true, message: '检测水平不能为空',tagger:'blur' }]">
                    <div class="figure_main" v-for="(item,index) in fromData.quality_limitation" :key="index">
                        <div class="figure_box">
                            <a-select v-model:value="item.field1" :placeholder="nullDatatext" :options="symbolString.all_symbol"></a-select>
                        </div>
                        <div class="figure_box">
                            <a-input-number class="inputs" v-model:value="item.field2" :step="0.5" placeholder="请输入检测水平" />
                        </div>
                        <div class="figure_box">
                            <a-select v-model:value="item.field3" :placeholder="nullDatatext" :options="symbolString.status"></a-select>
                        </div>
                    </div>
                </a-form-item>
                <div class="content_main" v-if="passJudgeArray.length > 0">
                    <div class="content_box" v-for="(item,index) in passJudgeArray" :key="index">
                        <div class="all_figure_main">
                            <div class="figure_main">
                                <div class="title">批量：</div>
                                <div class="figure_content">
                                    <div class="figure_box">
                                        <a-select v-model:value="item.batch_type" :placeholder="nullDatatext" :options="symbolString.batch" :disabled="index < 1 ? false : batchDisabled" @change="lockBatchType()" :dropdownMatchSelectWidth="false"></a-select>
                                    </div>
                                    <div class="figure_box">
                                        <span v-if="item.batch_type != 3">
                                            <a-select v-model:value="item.batch_field1" :placeholder="nullDatatext" :options="item.batch_type == 2 ? symbolString.all_symbol : symbolString.part_overtake" :dropdownMatchSelectWidth="false"></a-select>
                                        </span>
                                    </div>
                                    <div class="figure_box">
                                        <span v-if="item.batch_type != 3">
                                            <a-input-number class="inputs" v-model:value="item.batch_field2" :step="0.5" placeholder="请输入" />
                                        </span>
                                    </div>
                                    <div class="figure_box">
                                        <span v-if="item.batch_type != 3 && item.batch_type != 2">
                                            <a-select v-model:value="item.batch_field3" :placeholder="nullDatatext" :options="symbolString.part_symbol" :dropdownMatchSelectWidth="false"></a-select>
                                        </span>
                                    </div>
                                    <div class="figure_box">
                                        <span v-if="item.batch_type != 3 && item.batch_type != 2">
                                            <a-input-number class="inputs" v-model:value="item.batch_field4" :step="0.5" placeholder="请输入" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="all_figure_main">
                            <div class="figure_main">
                                <div class="title">抽样样本数量：</div>
                                <div class="figure_content">
                                    <div class="figure_box">
                                        <a-select v-model:value="item.sample_type" :placeholder="nullDatatext" :options="symbolString.sample" :dropdownMatchSelectWidth="false"></a-select>
                                    </div>
                                    <div class="figure_box" v-if="[4,5,6].indexOf(item.sample_type) < 0">
                                        <a-select v-model:value="item.sample_field1" :placeholder="nullDatatext" :options="symbolString.part_overtake" :dropdownMatchSelectWidth="false"></a-select>
                                    </div>
                                    <div class="figure_box" style="position: relative;">
                                        <span v-if="item.sample_type != 4">
                                            <a-input-number class="inputs" v-model:value="item.sample_field2" :step="0.5" placeholder="请输入" />
                                            <div v-if="item.sample_type == 5" style="position: absolute;right:5px;top:6px;">%</div>
                                        </span>
                                    </div>
                                    <div class="figure_box" v-if="[4,5,6].indexOf(item.sample_type) > -1"><!-- 当为 按单值时 本行占位 --></div>
                                    <div class="figure_box">
                                        <span v-if="[4,5,6].indexOf(item.sample_type) < 0">
                                            <a-select v-model:value="item.sample_field3" :placeholder="nullDatatext" :options="symbolString.part_symbol" :dropdownMatchSelectWidth="false"></a-select>
                                        </span>
                                    </div>
                                    <div class="figure_box">
                                        <span v-if="[4,5,6].indexOf(item.sample_type) < 0">
                                            <a-input-number class="inputs" v-model:value="item.sample_field4" :step="0.5" placeholder="请输入" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="all_figure_main">
                            <div class="figure_main">
                                <div class="title">接收数：</div>
                                <div class="figure_content">
                                    <div class="figure_box">
                                        <a-select v-model:value="item.receive_type" :placeholder="nullDatatext" :options="symbolString.type" :dropdownMatchSelectWidth="false"></a-select>
                                    </div>
                                    <div class="figure_box" v-if="item.receive_type != 6">
                                        <a-select v-model:value="item.receive_field1" :placeholder="nullDatatext" :options="symbolString.part_overtake" :dropdownMatchSelectWidth="false"></a-select>
                                    </div>
                                    <div class="figure_box">
                                        <a-input-number class="inputs" v-model:value="item.receive_field2" :step="0.5" placeholder="请输入" />
                                    </div>
                                    <div class="figure_box" v-if="item.receive_type == 6"><!-- 当为 按单值时 本行占位 --></div>
                                    <div class="figure_box">
                                        <span v-if="item.receive_type != 6">
                                            <a-select v-model:value="item.receive_field3" :placeholder="nullDatatext" :options="symbolString.part_symbol" :dropdownMatchSelectWidth="false"></a-select>
                                        </span>
                                    </div>
                                    <div class="figure_box">
                                        <span v-if="item.receive_type != 6">
                                            <a-input-number class="inputs" v-model:value="item.receive_field4" :step="0.5" placeholder="请输入" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="all_figure_main">
                            <div class="figure_main">
                                <div class="title">拒收数：</div>
                                <div class="figure_content">
                                    <div class="figure_box">
                                        <a-select v-model:value="item.rejection_type" :placeholder="nullDatatext" :options="symbolString.type" :dropdownMatchSelectWidth="false"></a-select>
                                    </div>
                                    <div class="figure_box" v-if="item.rejection_type != 6">
                                        <a-select v-model:value="item.rejection_field1" :placeholder="nullDatatext" :options="symbolString.part_overtake" :dropdownMatchSelectWidth="false"></a-select>
                                    </div>
                                    <div class="figure_box">
                                        <a-input-number class="inputs" v-model:value="item.rejection_field2" :step="0.5" placeholder="请输入" />
                                    </div>
                                    <div class="figure_box" v-if="item.rejection_type == 6"><!-- 当为 按单值时 本行占位 --></div>
                                    <div class="figure_box">
                                        <span v-if="item.rejection_type != 6">
                                            <a-select v-model:value="item.rejection_field3" :placeholder="nullDatatext" :options="symbolString.part_symbol" :dropdownMatchSelectWidth="false"></a-select>
                                        </span>
                                    </div>
                                    <div class="figure_box">
                                        <span v-if="item.rejection_type != 6">
                                            <a-input-number class="inputs" v-model:value="item.rejection_field4" :step="0.5" placeholder="请输入" />
                                        </span>
                                    </div>
                                </div>
                                <div class="figure_op">
                                    <div class="icon_main" style="margin-right:15px;" @click="getAddPassJudge" v-if="passJudgeArray.length == (index + 1)">
                                        <img src="../../../assets/img/icon_add.png" title="增加" />
                                    </div>
                                    <div class="icon_main" @click="deletePassJudge(index)" v-if="index > 0">
                                        <img src="../../../assets/img/icon_delete.png" title="删除" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a-form-item label="操作：" style="margin-top:30px;">
                    <a-button class="btn" type="primary" @click="handle_submit">保存</a-button>
                    <a-button class="btn" @click="$router.back()">返回</a-button>
                </a-form-item>
            </a-form>

        </div>
    </div>
</template>

<script>
import {message} from 'ant-design-vue';
import {
    getQuotaCateList,
    getQuotaDetail,
    getSymbolString,
    saveQuotaInfo
} from '@/api/qms.js'

export default {
    components: {
        
    },
    data() {
        return {
            labelColEdit: {
                span: 4
            },
            wrapperColEdit: {
                span: 18
            },
            fromData:{
                id:'',
                cate_id:undefined,
                name:'',
                programme:'',
                detection_level:'',
                quality_limitation:[
                    {field1:undefined,field2:'',field3:undefined},
                    {field1:undefined,field2:'',field3:undefined}
                ],
                pass_judge:[],
            },
            passJudgeArray:[],
            classifyListData:[],
            batchDisabled:false,
            symbolString:'',
            nullDatatext:'请选择',
            query:'',
        }
    },
    created() {
        this.query = this.$route.query
        this.getAddPassJudge()
        this.getQuotaCateList()
        this.getSymbolString()

        if(this.query.id){
            this.getQuotaDetail()
        }

        //从小到大排序
        // var points = [
        //     {name:'KBC',data:"1"},
        //     {name:'CBC',data:"3"},
        //     {name:'BBC',data:"7"},
        //     {name:'EBC',data:"5"},
        //     {name:'MBC',data:"11"},
        //     {name:'ABC',data:"9"}
        // ];
        // points.sort(function(a, b){
        //     return a.data - b.data
        // })
        // console.log(points)
    },
    methods: {
        getAddPassJudge(){
            let passJudge = {
                //批量
                batch_type:1,batch_field1:3,batch_field2:'',batch_field3:1,batch_field4:'',
                //抽样样本数量
                sample_type:1,sample_field1:3,sample_field2:'',sample_field3:1,sample_field4:'',
                //接收数
                receive_type:1,receive_field1:3,receive_field2:'',receive_field3:1,receive_field4:'',
                //拒收数
                rejection_type:1,rejection_field1:3,rejection_field2:'',rejection_field3:1,rejection_field4:'',
            }
            this.passJudgeArray.push(passJudge)
            this.lockBatchType()
        },
        getQuotaCateList(){
            getQuotaCateList({}).then(res=>{
                this.classifyListData = res.data.list
            })
        },
        getSymbolString(){
            getSymbolString({}).then(res=>{
                this.symbolString = res.data
            })
        },

        getQuotaDetail(){
            getQuotaDetail({
                id:this.query.id
            }).then(res=>{
                this.fromData = res.data.list
                if(this.fromData.quality_limitation.length < 1){
                    this.fromData.quality_limitation.push(
                        {field1:undefined,field2:'',field3:undefined},
                        {field1:undefined,field2:'',field3:undefined}
                    )
                }else if(this.fromData.quality_limitation.length < 2){
                    this.fromData.quality_limitation.push(
                        {field1:undefined,field2:'',field3:undefined}
                    )
                }
                //处理数据
                let passJudgeData = []
                this.fromData.pass_judge.forEach(item=>{
                    let judfeData = {
                        //批量
                        batch_type:item.batch_type ? parseInt(item.batch_type) : undefined,
                        batch_field1:item.batch_range.field1 ? parseInt(item.batch_range.field1) : undefined,
                        batch_field2:item.batch_range.field2,
                        batch_field3:item.batch_range.field3 ? parseInt(item.batch_range.field3) : undefined,
                        batch_field4:item.batch_range.field4,
                        //抽样样本数量
                        sample_type:item.sample_type ? parseInt(item.sample_type) : undefined,
                        sample_field1:item.sample_range.field1 ? parseInt(item.sample_range.field1) : undefined,
                        sample_field2:item.sample_range.field2,
                        sample_field3:item.sample_range.field3 ? parseInt(item.sample_range.field3) : undefined,
                        sample_field4:item.sample_range.field4,
                        //接收数
                        receive_type:item.receive_type ? parseInt(item.receive_type) : undefined,
                        receive_field1:item.receive_range.field1 ? parseInt(item.receive_range.field1) : undefined,
                        receive_field2:item.receive_range.field2,
                        receive_field3:item.receive_range.field3 ? parseInt(item.receive_range.field3) : undefined,
                        receive_field4:item.receive_range.field4,
                        //拒收数
                        rejection_type:item.rejection_type ? parseInt(item.rejection_type) : undefined,
                        rejection_field1:item.rejection_range.field1 ? parseInt(item.rejection_range.field1) : undefined,
                        rejection_field2:item.rejection_range.field2,
                        rejection_field3:item.rejection_range.field3 ? parseInt(item.rejection_range.field3) : undefined,
                        rejection_field4:item.rejection_range.field4,
                    }
                    passJudgeData.push(judfeData)
                })
                this.passJudgeArray = passJudgeData
            })
        },

        deletePassJudge(index){
            this.passJudgeArray.splice(index,1)
        },

        lockBatchType(){
            if(this.passJudgeArray[0].batch_type){
                this.batchDisabled = true
                this.passJudgeArray.forEach(item=>{
                    return item.batch_type = this.passJudgeArray[0].batch_type
                })
            }else{
                if(this.passJudgeArray.length > 1){
                    let batchType = ''
                    this.passJudgeArray.forEach(item=>{
                        if(batchType == '' && item.batch_type){
                            batchType = item.batch_type
                        }
                    })
                    if(batchType != ''){
                        this.batchDisabled = true
                        this.passJudgeArray.forEach(item=>{
                            return item.batch_type = batchType
                        })
                    }
                }
            }
        },

        handle_submit(){
            //处理-接收质量限(AQL)
            this.fromData.quality_limitation.forEach(item=>{
                return {
                    field1:item.field1 ? item.field1 : '',
                    field2:item.field2 ? item.field2 : '',
                    field3:item.field3 ? item.field3 : ''
                }   
            })
            //处理-批量、抽样样本数量、接收数、拒收数
            let passJudgeData = []
            this.passJudgeArray.forEach(item=>{
                let judgeData = {
                    batch_type:item.batch_type ? item.batch_type : '',  
                    batch_range: {
                        field1: item.batch_type == 3 ? '' : (item.batch_field1 ? item.batch_field1 : ''),
                        field2: item.batch_type == 3 ? '' : item.batch_field2,
                        field3: [2,3].indexOf(item.sample_type) > -1 ? '' : (item.batch_field3 ? item.batch_field3 : ''),
                        field4: [2,3].indexOf(item.sample_type) > -1 ? '' : item.batch_field4,
                    },
                    sample_type:item.sample_type ? item.sample_type : '',
                    sample_range: {
                        field1: [4,5,6].indexOf(item.sample_type) > -1 ? '' : (item.sample_field1 ? item.sample_field1 : ''),
                        field2: item.sample_type == 4 ? '' : item.sample_field2,
                        field3: [4,5,6].indexOf(item.sample_type) > -1 ? '' : (item.sample_field3 ? item.sample_field3 : ''),
                        field4: [4,5,6].indexOf(item.sample_type) > -1 ? '' : item.sample_field4,
                    },
                    receive_type:item.receive_type ? item.receive_type : '',
                    receive_range: {
                        field1: item.receive_type == 6 ? '' : (item.receive_field1 ? item.receive_field1 : ''),
                        field2: item.receive_field2,
                        field3: item.receive_type == 6 ? '' : (item.receive_field3 ? item.receive_field3 : ''),
                        field4: item.receive_type == 6 ? '' : item.receive_field4,
                    },
                    rejection_type:item.rejection_type ? item.rejection_type : '',
                    rejection_range: {
                        field1: item.rejection_type == 6 ? '' : (item.rejection_field1 ? item.rejection_field1 : ''),
                        field2: item.rejection_field2,
                        field3: item.rejection_type == 6 ? '' : (item.rejection_field3 ? item.rejection_field3 : ''),
                        field4: item.rejection_type == 6 ? '' : item.rejection_field4,
                    }
                }
                passJudgeData.push(judgeData)
            })
            this.fromData.pass_judge = passJudgeData
            saveQuotaInfo({
                ...this.fromData
            }).then(res=>{
                message.success('保存成功');
                this.$router.back()
            })
        },
        
    }
}
</script>

<style lang="less" scoped>
.btn{
    margin-right:15px;
}

.page_content{
    margin:15px;
}
.page_detail{
    max-width:none;
    padding:15px;
    background:#ffffff;
}

.content_main{
    border:1px solid #eeeeee;
    border-radius:5px;
    padding:15px;

    .content_box{
        border-bottom:1px solid #eeeeee;
        padding-bottom:5px;
        margin-bottom:15px;
    }

    .content_box:last-child{
        border-bottom:0px;
        padding-bottom:0px;
        margin-bottom:-10px;
    }
}

.all_figure_main{
    display:flex;

    .figure_main{
        flex:5;
        margin-bottom:10px;

        .title{
            width:120px;
            padding-right:4px;
            line-height:32px;
            text-align:right;
            display:inline-block;
        }

        .figure_content{
            width:calc(100% - 185px);
            display:flex;
        }

        .figure_op{
            width:65px;
            display:flex;

            .icon_main{
                height:26px;
                width:26px;
                padding-top:3px;
                cursor:pointer;

                img{
                    width:100%;
                }
            }
        }
    }
}

.figure_main{
    display:flex;

    .figure_box{
        width:33%;
        margin-right:10px;

        .inputs{
            width:100%;
        }
    }
}


</style>